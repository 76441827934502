import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { truncateString } from '@utils/index'
import { Tooltip } from 'antd'

type IProps = {
  id: string
  name: string
  type: string
}

const SappBreadcrumbNotLink = ({ paths }: { paths: IProps[] }) => {
  const router = useRouter()
  const getCourseId = router?.query?.courseId ?? router.query.id
  return (
    <>
      {paths.map((path, index) => {
        let url = ''
        switch (path.type) {
          case 'PART':
            url = `/courses/${getCourseId}/section/${path?.id}`
            break
          case 'CHAPTER':
            url = `/courses/${getCourseId}/section/${paths?.[0]?.id}?unit_id=${path?.id}`
            break
          case 'UNIT':
            url = `/courses/${getCourseId}/section/${paths?.[0]?.id}?unit_id=${paths?.[1].id}`
            break
          case 'ACTIVITY':
            url = `/courses/${getCourseId}/section/${paths?.[0]?.id}?unit_id=${paths?.[1].id}`
            break
        }
        return (
          <span
            key={path?.id}
            className="flex items-center overflow-hidden text-ellipsis whitespace-nowrap"
          >
            <Link href={url} className="breadcrumbs__link" scroll={false}>
              <span className="inline-block w-full max-w-full cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap text-sm font-normal text-gray-1">
                <Tooltip title={path?.name} color="#ffffff" placement="bottom">
                  {path?.name}
                </Tooltip>
              </span>
            </Link>
            {index < paths.length - 1 && (
              <span className="inline-block overflow-hidden px-1 text-sm font-normal text-gray-1">
                {' '}
                /{' '}
              </span>
            )}
          </span>
        )
      })}
    </>
  )
}

export default SappBreadcrumbNotLink
