export const url = {
  getQuestionTabs: '/quiz',
  getCaseStudyQuizes: '/quiz/case-study',
  getTopicQuiz: '/question-topic',
  getQuestionDetail: '/question',
  getQuestionResult: '/question/results',
  getTopicDescription: '/question-topic',
  submitQuestion: '/quiz',
  submitCaseStudy: '/quiz/case-study',
  getResource: '/resource',
  getQuizAttempts: '/quiz-attempts',
  createQuizAttemp: '/quiz/quiz-attempt',
  getQuizAttemptsTable: '/quiz-attempts/table',
  getCaseStudyAttmptsTable: '/quiz-attempts/case-study/table',
  getQuizAttemptsChartData: '/quiz-attempts/chart-data',
  createTopicAttempt: '/quiz/case-study/topic-attempt',
  getAnswer: 'quiz-attempts/answers',
  getTopicAttemptDetail: '/quiz-attempts/topic/',
}

export default url
