import { CERTIFICATE_DETAIL } from '@utils/constants'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { PageLink } from 'src/constants'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { getMe, userReducer } from 'src/redux/slice/User/User'

interface IProps {
  children: JSX.Element
}

export const RouteGuard = ({ children }: IProps) => {
  const router = useRouter()
  const [authorized, setAuthorized] = useState(false)
  const userSlice = useAppSelector(userReducer)
  const dispatch = useAppDispatch()
  useEffect(() => {
    const callGetMe = async () => {
      if (userSlice.user.id || router.pathname === CERTIFICATE_DETAIL) {
        setAuthorized(true)
        return
      }

      try {
        await dispatch(getMe()).unwrap()
        setAuthorized(true)
      } catch (error) {}
    }

    callGetMe()
  }, [router.pathname, dispatch, userSlice.user.id])

  /**
   * @description Check if the current pathname is '/' redirect to '/dashboard'
   */
  useEffect(() => {
    // Check if the current pathname is '/'
    if (router.pathname === '/') {
      // Redirect to '/courses'
      router.replace(PageLink.COURSES)
    }
  })

  return authorized ? children : <></>
}
